<template>
  <div
    class="tool-tip__wrapper"
    :class="{
      'show': showToolTip,
      'bottom': toolTipPosition === 'bottom',
      'top': toolTipPosition === 'top',
      'arrow': !hideArrow,
      'arrow-top': arrowPositionVertically === 'top',
      'arrow-bottom': arrowPositionVertically === 'bottom',
      'arrow-right': arrowPositionHorizontally === 'right',
      'arrow-left': arrowPositionHorizontally === 'left',
      'arrow-middle': arrowPositionHorizontally === 'middle',
      'has-padding': hasPadding
    }"
  >
    <a
      class="close"
      @click="close"
      v-if="allowClose"
    >
      <NavigationClose />
    </a>
    <div class="tool-tip__content">
      <slot />
    </div>
  </div>
</template>

<script>
import NavigationClose from '~/assets/icons/navigation_close.svg?component'

export default {
  components: {
    NavigationClose
  },
  props: {
    showToolTip: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    toolTipPosition: {
      type: String,
      required: false,
      default() {
        return 'top'
      }
    },
    hideArrow: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    arrowPositionVertically: {
      type: String,
      required: false,
      default() {
        return 'bottom'
      }
    },
    arrowPositionHorizontally: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    hasPadding: {
      type: Boolean,
      default() {
        return true
      }
    },
    allowClose: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.has-padding {
  padding: $default-margin-padding-width;
}

.tool-tip__wrapper {
  display: block;
  visibility: hidden;
  position: absolute;
  width: calc(100% - 2.5rem);
  min-height: 1rem;
  background-color: $color__content--background__secondary;
  box-shadow: 0 .188rem .375rem $color--nav__main--background;
  z-index: 600;
  opacity: 0;
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &.bottom {
    top: calc(100% + 1rem);
  }

  &.top {
    bottom: calc(100% + 1rem);
  }

  &.arrow::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  &.arrow {
    &.arrow-bottom {
      &::after {
        left: calc(50% - 1rem);
        top: 100%;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-top: 1rem solid $color__content--background__secondary;
      }
    }

    &.arrow-top {
      &::after {
        left: calc(50% - 1rem);
        bottom: 100%;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1rem solid $color__content--background__secondary;
      }
    }

    &.arrow-right {
      &::after {
        left: auto;
        right: .3rem;
      }
    }

    &.arrow-left {
      &::after {
        left: 1rem;
      }
    }

    &.arrow-middle {
      &::after {
        left: 50%;
      }
    }
  }

  .close {
    font-size: 1.8em;
    color: $color__text__default;
    position: absolute;
    z-index: 11;
    right: .75rem;
    top: .75rem;
    cursor: pointer;
  }
}
</style>
